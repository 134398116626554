.illustration {
  fill: var(--mantine-color-dark-4);
}

.backToStart {
  align-self: center;
  color: var(--mantine-color-black);
}

.root {
  padding-top: 30px;
  padding-bottom: 130px;
}

.inner {
  position: relative;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.75;
}

.content {
  padding-top: 220px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: $mantine-breakpoint-sm) {
    padding-top: 120px;
  }
}

.title {
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  text-align: center;
  font-weight: 900;
  font-size: 38px;

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: 32px;
  }
}

.description {
  max-width: 540px;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
}
